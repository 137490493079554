body {
    direction: rtl;
    text-align: right;
}
.navbar-brand {
    margin-left: 1rem;
    margin-right: 0;
}
.ml-auto, .mx-auto {
    margin-left: 0 !important;
    margin-right: auto !important;
}
.address-bar {
    margin-right: 104px;
    margin-left: 0;
}
.social-icons {
    text-align: left;
}
.social-icons .list-inline li {
    padding-left: 0;
}
.header-search {
    left: -12px;
    right: auto;
}
.header-search-form form input {
    text-align: left;
}
.header-search-form form input::placeholder {
    color: #fff;
    opacity: 1; 
}
.header-search-form form input:-ms-input-placeholder {
    color: #fff;
}
.header-search-form form input::-ms-input-placeholder {
    color: #fff;
}
.section-title {
    max-width: 710px;
    margin-left: auto;
    margin-right: auto;
}
.post-meta ul li {
    padding-right: 0;
    padding-left: 10px;
}
.owl-carousel { 
    direction: ltr;
}
.owl-carousel .owl-item { 
    direction: rtl; 
}
.blog-info {
    padding-right: 20px;
    padding-left: 20px;;
}
.date-box {
    right: 20px;
    left: auto;
}
.panel:before {
    right: -2px;
    left: auto;
}
.panel-title a {
    padding: 15px 30px 15px 55px;
}
.panel-title a:before {
    left: 25px;
    right: auto;
}
.address-area div {
    padding-right: 45px;
    padding-left: 0;
}
.address-area i {
    right: 0;
    left: auto;
}
aside.ScrollUpButton__Container.ScrollUpButton__Toggled {
    right: auto!important;
    left: 20px;
}
.search-form .btn-default {
    left: 0;
    right: auto;
}
.side-widget h3::before {
    right: 0;
    left: auto;
}
.single-post {
    padding: 0 130px 0 0;
}
.single-post img {
    right: 0;
    left: auto;
}
.comments-title::before {
    right: 0;
    left: auto;
}
.single-comment {
    padding: 0 100px 0 0;
}
.single-comment .reply {
    left: 0;
    right: auto;
}
.single-comment img {
    right: 0;
    left: auto;
}
.listing-badges .featured {
    float: right;
    transform: rotate(45deg);
    left: auto;
    right: -67px;
}
.list-tags li {
    margin-left: 5px;
    margin-bottom: 5px;
}
.list-tags a {
    padding: 0;
    border-right: 20px;
}

@media only screen and (max-width : 767px) {
    .menu-shrink .navbar-brand {
        right: 15px;
        left: auto;
    }
    .menu-shrink.navbar-light .navbar-toggler {
        margin-left: 15px;
        margin-right: 0;
    }
    .blog-info {
        padding-right: 0;
    }
    .panel-title a {
        padding: 13px 13px 13px 55px;
    }
    .address-area div {
        padding-right: 0;
    }
    .navbar-light .navbar-nav .nav-link {
        padding: 10px 15px;
    }
    .social-icons {
        text-align: center;
    }
    .margin-top-60 {
        margin-top: 40px;
    }
    .pricing-container {
        display: block;
    }
}

@media only screen and (min-width : 768px) and (max-width : 991px) {
    .header-search {
        left: -30px;
    }
    .offset-lg-2 {
        margin-right: 0;
    }
    .blog-info {
        padding-right: 15px;
    }
    .header-search {
        left: -25px;
    }
}

@media only screen and (min-width : 992px) and (max-width : 1200px) {

}
