@media only screen and (max-width: 767px) {
    .dilay-table {
        width: 200px;
        font-family: "Tajawal", sans-serif;
        height: 150px;
        position: absolute;
        top: 10%;
        right: 45%;
        color: white;
        z-index: 1;
    }

    #questionDetails .blog-item .blog-img img {
        height: 160px;
        object-fit: cover;
    }

    .diplay-table {
        top: 35%;
    }

    .hotLinks {
        margin-bottom: 10px;
    }

    .floatRightOnSmallScreen {
        float: right;
    }

    .hotLinks .row > div:nth-child(1), .hotLinks .row > div:nth-child(2), .hotLinks .row > div:nth-child(3) {
        border-left: none;
    }
    .hotLinks .row .col-sm-12.col-md-3 div{
        margin: 0;
    }

    .hotLinks .row h3 {
        margin-bottom: 10px;
    }

    .blog-item .cards-icons {
        position: absolute;
        z-index: 1;
        top: 10px;
        bottom: 0;
        left: 10px;
        direction: ltr;
        text-align: left;
    }

    .display-table-cell .display-table-cell-drop {
        margin-left: 0;
    }

    .header-search-responsive {
        padding: 20px 10px;
        -webkit-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        display: block;
    }

    .header-live-stream-responsive {
        width: fit-content;
        flex: auto;
        text-align: end;
        display: block;
    }

    .header-live-stream-responsive .btn {
        background-color: #C61122;
        color: white;
        border: none;
        cursor: pointer;
        width: 130px;
        height: 35px;
        justify-content: center;
        border-radius: 3px;
        text-align: center;
    }

    .header-search-responsive .search-toggle {
        background-color: transparent;
        border: medium none;
        display: block;
        padding: 0;
    }

    .top-header {
        display: none;
        background: #FFFCF7;
    }

    .mega {
        display: none !important;
    }

    .megaOnMobile {
        display: block;
    }

    .megaOnDesktop {
        display: none;
    }

    .hideOnBigScreen {
        display: none;
    }

    .showOnSmallScreen {
        display: block;
    }

    .modal-dialog {
        width: 95%;
        margin-top: -65px;
    }

    .navbar-light {
        padding-left: 15px;
        padding-right: 15px;
    }

    .navbar-light .navbar-nav .nav-link {
        text-align: center;
    }

    .navbar-light .navbar-brand {
        position: relative;
        top: 0;
    }

    .navbar-light .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(199, 157, 82, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    }

    .menu-shrink.navbar-light .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(199, 157, 82, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    }

    .navbar-light .navbar-toggler {
        border: none !important;
        border-color: #C79D52;
        border-radius: 0;
    }

    .menu-shrink.navbar-light .navbar-toggler {
        border-color: #ffffff;
        margin-right: 15px;
    }

    .address-area div {
        padding-left: 0;
        text-align: center;
    }

    .address-area i {
        position: relative;
        display: block;
        margin-bottom: 15px;
    }

    .pagination li a, .pagination li span {
        min-width: 28px;
    }

    .work-details {
        margin-top: 0;
    }

    .list-inline li {
        padding-left: 3px;
    }

    .menu-shrink .navbar-brand {
        top: 0 !important;
        left: 15px;
    }

    .logo img {
        margin-top: 1px;
        height: 60px;
        width: 40px;
    }

    .address-bar {
        margin-left: auto;
        text-align: right;
    }

    .navbar-nav.ml-auto {
        float: none !important;
        margin-top: 5px;
    }

    .header-search-form form input {
        font-size: 35px;
    }

    .single-slider-item {
        /*height: 300px;*/
        height: 400px;
        padding: 70px 0;
        /*margin-bottom: 20px;*/
    }

    .single-slider-item:after {
        content: '';
        position: absolute;
        top: 10px;
        left: 0;
        right: 0;
        bottom: 0;
        height: 400px;
        z-index: 0;
        background: linear-gradient(to bottom, transparent 0%, black 100%);
    }

    .single-slider-item h1 {
        font-size: 32px;
        line-height: 45px;
        margin: -5px 0 15px;
    }

    .header-search {
        display: none;
    }

    .header-live-stream {
        display: none;
    }

    .header-search-responsive {
        padding: 20px 10px;
        -webkit-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
    }

    .header-live-stream-responsive {
        width: fit-content;
        flex: auto;
        text-align: end;
    }

    .menu-shrink.navbar-light {
        padding: 15px 0;
    }

    .owl-thumbs {
        position: static;
        transform: translate3d(0px, 0, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1) !important;
        -webkit-transform: translate3d(0px, 0, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1) !important;
        -ms-transform: translate3d(0px, 0, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1) !important;
    }

    .owl-thumb-item {
        transform: translateX(0) rotateZ(0) scale(.90);
    }

    .slide-service-item {
        background-color: #f7f7f7;
    }

    .slide-service-item::before {
        display: none;
    }

    .services {
        margin-top: 0;
    }

    .section-title-bg {
        display: none;
    }

    .ptb-100 {
        padding: 50px 0;
    }

    .cta-info {
        margin-top: 0;
        margin-bottom: 30px;
    }

    .cta-info h2 {
        font-size: 25px;
        margin-top: 0;
    }

    .cta-info p {
        margin: 12px 0 20px;
    }

    .date-box {
        padding: 20px 20px 10px;
        font-size: 30px;
    }

    .blog-info {
        padding-left: 0;
        padding-top: 15px;
        padding-right: 0;
    }

    .post-content {
        padding: 0 20px 20px 20px;
    }

    .comments-area {
        padding: 20px 0;
    }

    .comments-form {
        padding: 20px 0;
    }

    .title-meta h2 a {
        font-size: 18px;
    }

    .video-inner-content h1 {
        font-size: 35px;
        margin: 72px 0 15px;
    }

    .video-inner-content p {
        max-width: 415px;
    }

    .video-area {
        height: auto;
        padding: 80px 0 30px;
    }

    .service-item h3 {
        font-size: 18px;
    }

    .single-testimonial-item p {
        font-size: 16px;
    }

    .count-box h2 {
        font-size: 30px;
    }

    .content-box {
        padding: 30px 30px;
        margin-bottom: 40px;
    }

    .filter-menu {
        margin-top: 0;
    }

    .filter-menu button {
        margin-top: 3px;
    }

    .plan.featured {
        transform: translateY(0);
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        margin: 30px 0;
    }

    .section-title h2 {
        font-size: 30px;
    }

    .testimonial-slides .owl-nav div {
        top: 5%;
    }

    .single-testimonial-item p {
        margin: 20px auto 30px;
    }

    .faq-content {
        margin-top: 0;
    }

    .content-box h3 {
        margin: 0;
    }

    #accordion .panel-title a {
        padding: 15px 55px 15px 15px;
        font-size: 16px;
    }

    #accordion .panel-body {
        padding: 0 15px 15px;
    }

    .partners-slides {
        padding: 0 20px;
    }

    .partners-slides .owl-item img {
        width: 150px;
        margin: auto;
    }

    .footer {
        padding: 25px 0;
        text-align: center;
    }

    .email {
        margin: 30px 0;
    }

    .social-icons {
        text-align: center;
    }

    .footer .copyright {
        margin: 15px;
    }

    .scrolltop {
        bottom: 100px;
    }

    .bread-cumbs-area {
        padding: 40px 0 30px;
        height: 200px;
    }

    .bread-cumbs-area h1 {
        font-size: 25px;
        margin: 0 0 10px;
    }

    .main-blog {
        padding: 20px 0 40px;
    }

    .all-post .button {
        margin: 0 auto 0;
    }

    .side-widget h3 {
        font-size: 20px;
    }

    .plus {
        width: 50px;
        height: 50px;
    }

    .video-inner-content {
        margin-top: 0;
    }

    .plus:after {
        font-size: 25px;
        line-height: 53px;
    }

    .panel-title a {
        padding: 13px 55px 13px 13px;
        font-size: 15px;
    }

    .panel-body {
        padding: 15px 10px 15px;
    }

    .blog-item {
        margin-top: 0;
        margin-bottom: 20px;
    }

    .blog-details .title-meta h2 {
        padding-right: 20px;
    }

    .title-meta h2 {
        padding-right: 20px;
    }

    .comments-title {
        font-size: 20px;
    }

    .mrl-50 {
        margin-left: 0;
    }

    .home-banner-area {
        height: 100%;
        padding: 70px 0;
        margin-bottom: 20px;
    }

    .home-banner-area h1 {
        font-size: 35px;
        line-height: 50px;
    }

    .home-video-area {
        height: 600px;
    }

    .video-text-area h1 {
        font-size: 35px;
        line-height: 50px;
    }

    .feature-area {
        padding: 0;
    }

    .single-feature h3 {
        font-size: 20px;
    }

    .hero-text {
        font-size: 16px;
    }
}

@media only screen and (max-width: 769px) {
    .diplay-table {
        top: 26%;
    }

    /*.live_col_social > div{*/
    /*    height:auto !important;*/
    /*}*/
    .blog-item .blog-img > img, .blog-item .blog-img > span > img {
        /*min-height: 150px;*/
        /*height: 150px;*/
    }

    #homePageCollections .blog-item .blog-img img {
        /*height: 180px;*/
        /*min-height: 180px;*/
        /*object-fit: cover;*/
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog-item .cards-icons {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 150px;
        bottom: 0;
        left: 0;
        direction: ltr;
        text-align: left;
        margin-left: 5px;
    }

    .responsive {
        overflow-x: scroll;
    }

    .hotLinks {
        /*margin-bottom: -80px;*/
    }

    .header-search-responsive {
        padding: 20px 10px;
        -webkit-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
    }

    .display-table-cell .display-table-cell-drop {
        margin-left: 0;
    }

    .header-live-stream-responsive {
        width: fit-content;
        flex: auto;
        text-align: end;
    }

    .header-live-stream-responsive .btn {
        background-color: #C61122;
        color: white;
        border: none;
        cursor: pointer;
        width: 130px;
        height: 35px;
        justify-content: center;
        border-radius: 3px;
        text-align: center;
    }

    .header-search-responsive .search-toggle {
        background-color: transparent;
        border: medium none;
        color: #575757;
        display: block;
        padding: 0;
    }

    .modal-dialog {
        width: 90%;
        margin-top: -60px;
    }

    .menu-shrink .navbar-brand.logo-2 {
        /*top: 8px;*/
        top: 0;
        margin-top: 0;
    }

    .address-bar ul li a {
        font-size: 13px;
    }

    .logo > img {
        margin-top: 50px;
        height: 60px;
        width: 40px;
    }

    .main-nav .navbar-brand {
        left: 15px;
    }

    .navbar-light .navbar-nav .nav-link {
        padding-right: 8px;
        padding-left: 8px;
        font-size: 14px;
    }

    .header-search {
        right: -31px;
        top: -6px;
    }

    .owl-thumbs {
        position: static;
    }

    .owl-thumb-item {
        max-width: 245px;
        margin: 0;
        transform: translateX(0) rotateZ(0) scale(.90);
    }

    .slide-service-item {
        background-color: #f7f7f7;

    }

    .slide-service-item::before {
        display: none;
    }

    .single-slider-item {
        height: 400px;
        padding: 110px 0;
        /*margin-bottom: 40px;*/
    }

    .single-slider-item:after {
        content: '';
        position: absolute;
        top: 10px;
        left: 0;
        right: 0;
        bottom: 0;
        height: 400px;
        z-index: 0;
        background: linear-gradient(to bottom, transparent 0%, black 100%);
    }

    .slide-service-item h3 {
        font-size: 20px;
    }

    .slide-service-item i {
        font-size: 50px;
    }

    .services {
        margin-top: 0;
    }

    .section-title-bg {
        font-size: 75px;
        top: 10px;
    }

    .service-item h3 {
        font-size: 20px;
    }

    .ptb-100 {
        padding: 70px 0;
    }

    .ptb-50 {
        padding: 50px 0;
    }

    .cta-info {
        margin-top: 0;
    }

    .cta-info h2 {
        font-size: 25px;
        margin-top: 0;
    }

    .cta-info p {
        margin: 15px 0 20px;
    }

    .filter-menu {
        margin-top: 0;
    }

    .team-box .title {
        font-size: 20px;
    }

    .team-slides .owl-nav {
        margin: 15px auto 0;
        padding-bottom: 10px;
    }

    .blog-info {
        padding-left: 15px;
    }

    .date-box {
        left: 15px;
        padding: 25px 25px 15px;
        font-size: 35px;
    }

    .title-meta h2 a {
        font-size: 20px;
    }

    .video-area {
        height: 100%;
        padding: 60px 0 40px;
    }

    .video-inner-content h1 {
        font-size: 40px;
        margin: 75px 0 22px;
    }

    .count-box p {
        font-size: 20px;
    }

    .count-box h2 {
        font-size: 35px;
    }

    .faq-content {
        margin-top: 0;
    }

    .content-box h3 {
        margin: 0;
    }

    .content-box.color-effect-1 {
        margin-bottom: 50px;
    }

    .partners-slides .owl-nav {
        margin: 35px auto 0;
    }

    .single-slider-item h1 {
        font-size: 40px;
    }

    .email {
        margin: 40px 0;
    }

    .footer {
        padding: 30px 0;
    }

    .scrolltop {
        bottom: 95px;
    }

    .bread-cumbs-area {
        height: 200px;
        padding: 60px 0 50px;
    }

    .bread-cumbs-area h1 {
        font-size: 35px;
        margin: 0 0 12px;
    }

    .main-blog {
        padding: 20px 0 60px;
    }

    .home-banner-area {
        height: 100%;
        padding: 110px 0;
        margin-bottom: 40px;
    }

    .home-banner-area h1 {
        font-size: 40px;
    }

    .home-video-area {
        height: 600px;
    }

    .home-video-area h1 {
        font-size: 40px;
    }

    .feature-area {
        padding: 30px 0 0;
    }

    .single-feature h3 {
        font-size: 17px;
    }

    .attachmentModal > div {
        width: 70%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .dilay-table {
        width: 300px;
        font-family: "Tajawal", sans-serif;
        height: 150px;
        position: absolute;
        top: 10%;
        right: 45%;
        color: white;
        z-index: 1;
    }

    .hotLinks {
        /*margin-bottom: -80px;*/
    }

    .single-slider-item {
        height: 400px;
        padding: 110px 0;
        /*margin-bottom: 40px;*/
    }

    .single-slider-item:after {
        content: '';
        position: absolute;
        top: 10px;
        left: 0;
        right: 0;
        bottom: 0;
        height: 400px;
        z-index: 0;
        background: linear-gradient(to bottom, transparent 0%, black 100%);
    }

    .single-slider-item h1 {
        font-size: 45px;
    }

    .owl-thumb-item {
        max-width: 300px;
    }

    .service-item h3 {
        font-size: 20px;
    }

    .cta-info h2 {
        font-size: 30px;
    }

    .title-meta h2 a {
        font-size: 22px;
    }

    .scrolltop {
        bottom: 90px;
    }

    .email {
        margin: 40px 0;
    }

    .bread-cumbs-area h1 {
        font-size: 35px;
        margin: 0 0 20px;
    }

    .bread-cumbs-area {
        height: 200px;
        padding: 70px 0 50px;
    }

    .main-blog {
        padding: 40px 0 60px;
    }

    .home-banner-area h1 {
        font-size: 45px;
    }

    .home-video-area h1 {
        font-size: 45px;
    }

    .feature-area {
        padding: 60px 0 0;
    }

    .single-feature h3 {
        font-size: 18px;
    }

    .modal-dialog {
        width: 90%;
        margin-top: -60px;
    }

    .attachmentModal > div {
        width: 70%;
    }
}

@media only screen and (max-width: 420px) {
    .blog-item .blog-img img {
        /*height: 150px;*/
        /*min-height: 140px;*/
    }

    #homePageCollections .blog-item .blog-img img {
        /*height: 160px;*/
        /*min-height: 160px;*/
        /*object-fit: cover;*/
    }

    .attachmentModal > div {
        width: 90%;
    }

}

@media only screen and (max-width: 414px) {
    .diplay-table {
        top: 35%;
    }

    .hotLinks .row h3 {
        font-size: 18px;
    }
}

@media only screen and (max-width: 430px) {
    .diplay-table {
        top: 20%;
        right: 0;
        width: 100%;
    }

    .diplay-table h1 {
        font-size: 28px !important;
    }

    .hotLinks .row h3 {
        font-size: 18px;
    }

    .small-font {
        font-size: 14px !important;
    }

    .nice-dates-popover {
        width: 300px !important;
    }

    .fullWidthOnMobile {
        width: 100%;
    }

    .bread-cumbs-area {
        height: 240px;
    }
    .post-img img, .post-img > div {
        max-height: 220px;
    }
    #blog .single-post{
        min-height: 7.5rem !important;
    }
    #blog .single-post p{
        font-size: 1rem;
        line-height: 1.2rem;
        min-height: 3.5rem;
        -webkit-line-clamp: 3;
    }
    #forSidebarComponent .single-post h4{
        padding-right: 180px;
    }
}
@media only screen and (min-width: 991px) and (max-width: 1200px) {
    .navbar-light .navbar-nav .nav-link {
        padding: 15px 25px;
    }
}
