/*
 Default: #C79D52;
 */

h2, a:focus {
	color: #C79D52;
}

.button, .center-wrap button button {
	border: 1px solid #C79D52;
	background-color: #C79D52;
}

.plan .button:hover {
	border-color: #C79D52;
}

.plan .button a, .glyph i {
	color: #C79D52;
}

.plan.featured .button {
	background-color: #C79D52;
}

.social-icons ul li a:hover {
	background-color: #C79D52;
}

.navbar-light .navbar-nav > .nav-item:hover,
.navbar-light .navbar-nav > .nav-item:active {
	background-color: #FFFCF7;
}

.owl-thumb-item i {
	color: #FFFCF7;
}
.navbar-light .navbar-nav .nav-link:hover {
	color: #C79D52 !important;
}

.navbar-light .navbar-nav .nav-link.active {
	color: #C79D52 !important;
}

.navbar-light .navbar-nav .nav-link {
	color: #6E5835  !important;
}

.navbar-light.fixed-top, .search-modal, .home-video-area::before, .owl-thumb-item.active, .owl-thumb-item:hover {
	background-color: #C79D52;
	color: #6E5835  !important;
}

.navbar-light.fixed-top {
	background-color: #FFFCF7 !important;

}

.filter-menu button.programs-filter-btn-active, .filter-menu button:hover {
	background-color: #C79D52;
}

.work-details .icon li span, .team-box .title, .team-box .icon li a, .team-slides .owl-nav div {
	color: #C79D52 !important;
}

.team-box .icon li a:hover {
	background: #C79D52;
}

.date-box {
	background-color: #C79D52;
}

.blog-item:hover .title-meta h2 a {
	color: #C79D52;
}

.post-meta ul li a:hover {
	color: #C79D52;
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
	background-color: #C79D52;
	border-color: #C79D52;
}

.side-widget h3::before {
	background: #C79D52;
}

.search-form .btn-default {
	border-color: #C79D52;
	background: #C79D52;
}

.side-widget .list-group li a:hover {
	color: #C79D52;
}

.single-post h4 a:hover {
	color: #C79D52;
}

.list-tags li a:hover {
	background-color: #C79D52;
}
.list-tags li span:hover {
	background-color: #C79D52;
}

.sharing-link ul li i:hover {
	background-color: #C79D52;
}

.content-box:hover .box-icon-effect-1a .box-icon i {
	color: #C79D52;
}

.work-details .box-content {
	background: #C79D52;
}

.comments-title::before {
	background: #C79D52;
}

.single-comment .reply:hover {
	background-color: #C79D52;
}

.video-area:before {
	background: #C79D52;
}

.plus, .post-meta ul li .fa {
	color: #C79D52;
}

.plus:hover {
	color: #C79D52;
}

.tab-menu ul li.active a {
	background-color: #C79D52;
}

.plan.featured .listing-badges .featured {
	color: #C79D52;
}

.plan.featured .plan-price, .content-box.color-effect-1, .footer {
	background-color: #C79D52;
}

.single-testimonial-item i, .partners-slides .owl-nav div {
	color: #C79D52 !important;
}

.client-info h3, .testimonial-slides .owl-nav div {
	color: #C79D52 !important;
}

.panel-title a:before, .panel-title a.collapsed:before {
	color: #C79D52;
	border: 1px solid #C79D52;
}

.social-icons.bottom ul li a {
	background-color: #6E5835;
}

.social-icons.bottom ul li a:hover {
	color: #6E5835;
}

.about-info i {
	color: #C79D52;
}

.about-info:hover {
	background-color: #C79D52;
}

.plan .btn-a {
	color: #C79D52;
}
